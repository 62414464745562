// src/Login.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from './firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect if user is already logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/home');
      }
    });
    return unsubscribe; // Clean up the listener on unmount
  }, [navigate]);

  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/home'); // Explicitly navigate after sign-in
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
      }}
    >
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          p={4}
          sx={{
            backgroundColor: '#fff',
            borderRadius: '12px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Welcome to Gen AI Bot Store
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Sign in to explore and chat with different AI-powered bots!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSignIn}
            sx={{
              mt: 2,
              backgroundColor: '#4285F4',
              '&:hover': { backgroundColor: '#357ae8' },
            }}
          >
            Sign In with Google
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Login;
mc