// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCK0EtPNV-LqoXl62FDFpu6KIUOwSLiBnA",
  authDomain: "mybotostore.firebaseapp.com",
  projectId: "mybotostore",
  storageBucket: "mybotostore.firebasestorage.app",
  messagingSenderId: "642884685503",
  appId: "1:642884685503:web:5f440e9e852dee684ec00a"
  
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Google Auth Provider
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, signInWithPopup, signOut };
