// src/BotStore.js
import React from 'react';
import { auth, signOut } from './firebaseConfig';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const bots = [
  { id: 1, name: 'English Tutor Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 2, name: 'Insurance Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 3, name: 'Travel Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 4, name: 'Data Analytics Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 5, name: 'Disaster recovery Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 6, name: 'My Career Pool Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 7, name: 'Insurance Policy Compare Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 8, name: 'Enter prise RAG Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' },
  { id: 9, name: 'Kavach Bot', url: 'http://twin.neuronextech.in:3000/chatbot/d11ce639-8f89-4bef-868a-1f2703940c87' }
];

function BotStore() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/');
  };

  const handleStartChat = (botUrl) => {
    window.open(botUrl, '_blank');
  };

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Gen AI Bot Store</Typography>
        <Button variant="outlined" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>

      <Grid container spacing={3} mt={2}>
        {bots.map((bot) => (
          <Grid item xs={12} sm={6} md={4} key={bot.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{bot.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Chat with {bot.name.toLowerCase()}.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleStartChat(bot.url)}
                >
                  Start Chat
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default BotStore;
